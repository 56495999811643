<template>
  <div class="my-6 text-start">
    <h3 class="font-bold">Lista de {{ route.name }}</h3>
    <span class="text-sm text-gray-500"
      >Aqui você pode observar todos os itens relacionados aos Promoters como
      suas respectivas ações.
    </span>
  </div>
  <div class="flex mb-6 justify-end">
    <div class="ml-3">
      <router-link to="/promoter/criar">
        <Button color="primary" text="Criar novo Promoter" />
      </router-link>
    </div>
  </div>
  <div class="card shadow-lg border rounded-lg p-8 bg-white mt-10">
  <div class="card">
    <DataTable
      :headers="headers"
      :data="data"
      :options="options"
      :exports="true"
      color="primary"
    />
  </div>
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, DELETE } from "../../services/api";
import { useRoute } from "vue-router";


export default {
  name: "Promoter",
  components: {
    DataTable,
    Button,
  },
  setup() {
    const headers = [
      { key: "Id", value: "promoters_id" },
      { key: "CPF/CNPJ", value: "documento" },
      { key:"Email", value:"usuario"},
      { key:"Ações", value:""},
    ];

    const route = useRoute();
    const modal = inject("modal");
    const alert = inject("alert");
    const loader = inject("loading");
    const data = ref([]);


    onMounted(async () => {
      try {
        loader.open();
        data.value = await GET("promoters");           
        loader.close();
      } catch (e) {
        loader.close();
        alert.open("Erro", e, "danger");
      }
    });

  /*  const deleteItem = (promoter) => {


      loader.open();
      const deleteFunction = async function() {
        try {
          loader.open();
          await DELETE(`promoters/${promoter.promoters_id}`);
          data.value = data.value.filter((item) => item.promoters_id != promoter.promoters_id);
          loader.close();
          alert.open("Sucesso!", `Usuário deletado com sucesso!`, "success");
        } catch (e) {
          loader.close();
          alert.open("Erro", e, "danger");
        }
      };
      modal.open(
        "Atenção",
        "Deseja realmente excluir esse usuário?",
        "warning",
        "Sim",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    }; */

    const options = [
      { icon: "pen", path: '/promoter/editar' , title: "Editar Promoter" },
     // { icon: "trash", action: (param) => deleteItem(param), title: "Excluir Promoter" },
    ];

    return { headers, data, options, route };
  },
};
</script>

<style></style>
